<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading" :searchFormData="searchFormData" :showRefresh="true" :showSearch="true" :showAdd="$hasAccess('device.plug/add')"
		 addName="新增插座" @getList="getList" @clearSearch="clearSearch" @addItemInTable="AddOne">

			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.device_id" filterable remote reserve-keyword clearable size="small" placeholder="按设备名称查询" :remote-method="remoteDevice" :loading="dLoading">
					<el-option v-for="item in deviceList" :key="item.device_id" :label="item.device_name" :value="item.device_id">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.plug_name" size="small" clearable placeholder="按插座名称查询"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.plug_num" size="small" clearable placeholder="按插座编号查询"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.plug_status" placeholder="按插座状态查询" clearable size="small">
					<el-option label="正常" :value="20">
					</el-option>
					<el-option label="异常" :value="30">
					</el-option>
					<el-option label="充电中" :value="40">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.online_status" placeholder="二路插座在线状态" clearable size="small">
					<el-option label="离线" :value="20">
					</el-option>
					<el-option label="在线" :value="10">
					</el-option>
				</el-select>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="plug_name" title="插座名称" align="center" min-width="60px" />
			<vxe-table-column slot="table-item" field="plug_num" title="插座编号" align="center" min-width="60px" />
			<vxe-table-column slot="table-item" field="plug_status" title="插座状态" align="center" min-width="60px">
				<template v-slot="{ row }">
					<span>{{types.plug_status[row.plug_status]||'未知状态'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="online_status" title="在线状态" align="center" min-width="60px">
				<template v-slot="{ row }">
					<span>{{types.online_status[row.online_status]||'未知状态'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="device_id" title="所属设备" align="center" min-width="200px">
				<template v-slot="{ row }">
					<div v-if="!row.device_id">无</div>
					<div v-if="row.device_id">{{row.device_name}}</div>
					<div class="vxe-sub" v-if="row.device_id">编号:{{row.device_no}}</div>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="create_user_name" title="创建者" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" title="操作" align="center" width="250">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="editOne(row)" v-if="$hasAccess('device.plug/edit')">编辑</el-button>
					<el-button size="small" plain @click="qrCode(row)">二维码</el-button>
					<el-button size="small" plain @click="deleteOne(row)" v-if="$hasAccess('device.plug/delete')">删除</el-button>
				</template>
			</vxe-table-column>
		</Tables>

		<!-- 新增 -->
		<el-dialog title="新增插座" :visible.sync="addDialogShow" :close-on-click-modal="false" :close-on-press-escape="false" width="500px">
			<el-form :model="addDialogFormData" :rules="addDeviceType == 0 ? rules: rules2" ref="addForm" label-width="120px" class="form">
				<el-radio-group class="add-dialog-radio-group" v-model="addDeviceType">
					<el-radio :label="1">{{types.add_plug_type[1]}}</el-radio>
					<el-radio :label="0">{{types.add_plug_type[0]}}</el-radio>
				</el-radio-group>
				<div v-if="addDeviceType == 1">
					<el-form-item label="生成插座数量" prop="create_num">
						<el-input v-model="addDialogFormData.create_num" style="width: 80%;" placeholder="输入需要生成插座的数量"></el-input>
					</el-form-item>
				</div>
				<div v-else>
					<el-form-item label="所属设备" prop="device_id">
						<el-select v-model="addDialogFormData.device_id" filterable remote reserve-keyword clearable placeholder="请选择设备" style="width: 80%;" :remote-method="remoteDevice" :loading="dLoading">
							<el-option v-for="device in deviceList" :key="device.device_id" :label="device.device_name" :value="device.device_id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="插座名称" prop="plug_name">
						<el-input v-model="addDialogFormData.plug_name" style="width: 80%;"></el-input>
					</el-form-item>
					<el-form-item label="插座序号" prop="plug_num">
						<el-input-number v-model="addDialogFormData.plug_num" controls-position="right" :min="1" :step="1" style="width: 80%;"></el-input-number>
					</el-form-item>
				</div>
			</el-form>

			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveAdd('addForm')" v-if="$hasAccess('device.plug/add')">保 存
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>

		</el-dialog>

		<!-- 编辑 -->
		<el-dialog title="编辑插座" :visible.sync="editDialogShow" :close-on-click-modal="false" :close-on-press-escape="false" width="500px">
			<el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="160px" class="form">
				<el-form-item label="所属设备" prop="device_id">
					<el-select v-model="editDialogFormData.device_id" filterable remote reserve-keyword clearable placeholder="请选择设备" style="width: 80%;" :remote-method="remoteDevice" :loading="dLoading">
						<el-option v-for="device in deviceList" :key="device.device_id" :label="device.device_name" :value="device.device_id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="插座名称" prop="plug_name">
					<el-input v-model="editDialogFormData.plug_name" :disabled="editDialogFormData.plug_name && editDialogFormData.plug_name.indexOf('P') != -1" style="width: 80%;"></el-input>
				</el-form-item>
				<el-form-item label="插座序号" prop="plug_num">
					<el-input-number v-model="editDialogFormData.plug_num" controls-position="right" :min="1" :step="1" :disabled="editDialogFormData.plug_name && editDialogFormData.plug_name.indexOf('P') != -1"
					 style="width: 80%;"></el-input-number>
				</el-form-item>
				<el-form-item label="插座状态" prop="plug_status">
					<el-radio-group v-model="editDialogFormData.plug_status">
						<el-radio :label="20">正常</el-radio>
						<el-radio :label="30">异常</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="插座在线状态" prop="plug_status">
					<el-radio-group v-model="editDialogFormData.online_status">
						<el-radio :label="10">在线</el-radio>
						<el-radio :label="20">离线</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit('editForm')" :disabled="editDisabled" v-if="$hasAccess('device.plug/edit')">保 存 编 辑
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>

		<!-- 二维码 -->
		<el-dialog title="插座二维码" :visible.sync="showQrCode" width="600px" height="650px" center class="qrcode" @closed="closedQrcode">
			<div class="qr-code">
				<div class="qr-code-box">
					<div class="qrcode-content" v-if="qrcode">
						<div class="qrcode-url">
							<img :src="qrcode" />
						</div>
					</div>

					<span slot="footer" class="dialog-footer">
						<el-button size="small" @click="qrcodeDownload('wxcode')">下载小程序码</el-button>
					</span>
				</div>

				<div class="qr-code-box">
					<div class="qrcode-content" v-if="linkQrcode">
						<div class="qrcode-url">
							<img :src="linkQrcode" />
						</div>
					</div>
					<span slot="footer" class="dialog-footer">
						<el-button size="small" @click="qrcodeDownload('linkcode')">下载二维码</el-button>
					</span>
				</div>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import Tables from "@/components/tables";

	export default {
		name: "device-plug-list",
		components: {
			Tables,
		},
		data() {
			return {
				// table 信息
				tableName: "费率信息",
				isLoading: false,
				tableData: [],
				totalPage: 0,
				searchFormData: {},
				// 类型
				types: {
					plug_status: {
						20: "正常",
						30: "异常",
						40: "充电中",
					},
					add_plug_type: {
						0: "其它设备",
						1: "新2路设备"
					},
					online_status:{
						10: "在线",
						20: "离线"
					}
				},
				// 规则
				rules: {
					device_id: [{
						required: true,
						message: "请选择所属设备",
						trigger: "change"
					}, ],
					plug_name: [{
						required: true,
						message: "请输入插座名称",
						trigger: "change"
					}, ],
					plug_num: [{
						required: true,
						message: "请输入插座编号",
						trigger: "change"
					}, ],
					plug_status: [{
						required: true,
						message: "请选择插座状态",
						trigger: "change"
					}, ]
				},
				rules2: {
					create_num: [{
						required: true,
						message: "请输入需要生成插座的数量",
						trigger: "change"
					}]
				},
				// 新增
				addDialogShow: false,
				addDialogFormData: {},
				addDeviceType: 1, // 0：其它设备；1：二路设备
				// 编辑
				editDialogShow: false,
				editDialogFormData: {},
				editDialogFormOriData: {},
				editNeedData: [
					"device_id",
					"plug_id",
					"plug_name",
					"plug_num",
					"plug_status",
					"online_status"
				],
				// 选择列表
				dLoading: false,
				deviceList: [],
				// 二维码弹窗
				showQrCode: false,
				selPlugDetail: undefined,
				qrcode: undefined, // 对应小程序码
				linkQrcode: undefined, // 对应关联到小程序码的二维码
			};
		},
		computed: {
			editDisabled() {
				for (let item of this.editNeedData) {
					if (this.editDialogFormData[item] != this.editDialogFormOriData[item]) {
						return false;
					}
				}
				return true;
			},
		},
		mounted() {
			this.canvasInit();
		},
		methods: {
			// 获取设备列表
			async getDeviceList() {
				const params = {
					token: this.$store.state.user.token,
					page: 1,
					size: 100,
				};
				const res = await this.$api.Device.GetDeviceList(params, {});
				this.deviceList = res.data;
			},
			// 表格
			async getList(currentPage, pageSize) {
				const params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize,
				};
				this.isLoading = true;
				const searchData = this.searchFormData;
				// 小区限制
				const group_id = this.$store.state.user.userInfo.group_id;
				if (!searchData.group_id && group_id) {
					searchData.group_id = group_id;
				}
				const res = await this.$api.Device.GetPlugList(params, searchData);
				this.tableData = res.data;
				this.totalPage = res.total;
				this.isLoading = false;
			},
			// 充值查询
			clearSearch() {
				this.searchFormData = {
					plug_id: "",
				};
			},
			// 重置表单
			resetFormData() {
				this.addDialogFormData = {};
				this.editDialogFormData = {};
				this.editDialogFormOriData = {};
				if (this.$refs.addForm) {
					this.$refs.addForm.resetFields();
				}
				if (this.$refs.editForm) {
					this.$refs.editForm.resetFields();
				}
			},
			// 新增
			AddOne() {
				this.resetFormData();
				// this.getDeviceList()
				this.addDialogShow = true;
			},
			// 编辑
			async editOne(row) {
				this.resetFormData();
				// this.getDeviceList()
				this.remoteDevice(row.device_name);
				const params = {
					token: this.$store.state.user.token,
					plug_id: row.plug_id,
				};
				const res = await this.$api.Device.GetPlugDetail(params);
				if (res) {
					const {
						device_id,
						plug_name,
						plug_num,
						plug_status,
						plug_id,
						online_status
					} = res;
					this.editDialogFormData = {
						device_id,
						plug_name,
						plug_num,
						plug_status,
						plug_id,
						online_status
					};
					this.editDialogFormOriData = {
						device_id,
						plug_name,
						plug_num,
						plug_status,
						plug_id,
						online_status
					};
				}
				this.editDialogShow = true;
			},
			// 删除
			deleteOne(row) {
				this.$confirm("确定要删除该插座吗", "提示", {
					cancelButtonClass: "btn-custom-cancel",
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(async () => {
					const params = {
						token: this.$store.state.user.token,
						plug_id: row.plug_id,
					};
					await this.$api.Device.DeletePlug(params);
					this.$notify({
						title: "成功",
						message: "删除成功",
						type: "success",
					});
					this.$refs.xTable.refreshTable();
				});
			},
			// 关闭弹窗
			closeDialog() {
				this.addDialogShow = false;
				this.editDialogShow = false;
			},
			// 新增保存
			saveAdd() {
				this.$refs.addForm.validate(async (valid) => {
					if (valid) {
						if (this.addDeviceType == 0) {
							const params = {
								token: this.$store.state.user.token,
								form: JSON.stringify(this.addDialogFormData),
							};
							await this.$api.Device.AddPlug(params);
						} else {
							const params = {
								token: this.$store.state.user.token,
								form: JSON.stringify(this.addDialogFormData),
							};
							await this.$api.Device.BatchAddPlug(params);
						}
						this.$notify({
							title: "成功",
							message: "新增成功",
							type: "success",
						});
						this.closeDialog();
						this.$refs.xTable.refreshTable();
					}
				});
			},
			// 编辑保存
			saveEdit() {
				this.$refs.editForm.validate(async (valid) => {
					if (valid) {
						const params = {
							token: this.$store.state.user.token,
							form: JSON.stringify(this.editDialogFormData),
						};
						await this.$api.Device.EditPlug(params);
						this.$notify({
							title: "成功",
							message: "编辑成功",
							type: "success",
						});
						this.closeDialog();
						this.$refs.xTable.refreshTable();
					}
				});
			},
			// canvas初始化
			canvasInit() {
				const canvas = document.createElement("canvas");
				canvas.id = "canvas1";
				console.log(canvas);
				canvas.width = 500;
				canvas.height = 510; // 560
				const ctx = canvas.getContext("2d");
				ctx.textAlign = "left";
				ctx.textBaseline = "top";
				ctx.font = "34px Microsoft YaHei";
				this.canvas = canvas;
				this.ctx = ctx;

				const canvas2 = document.createElement("canvas");
				canvas2.id = "canvas2";
				console.log(canvas2);
				canvas2.width = 500;
				canvas2.height = 510; // 560
				const ctx2 = canvas2.getContext("2d");
				ctx2.textAlign = "left";
				ctx2.textBaseline = "top";
				ctx2.font = "34px Microsoft YaHei";
				this.canvas2 = canvas2;
				this.ctx2 = ctx2;
			},
			// 二维码
			qrCode(row) {
				this.selPlugDetail = row;
				this.showQrCode = true;

				if (!row.app_qrcode || !row.link_qrcode) {
					const params = {
						token: this.$store.state.user.token,
						device_id: row.device_id,
						plug_id: row.plug_id,
					};
					let that = this;
					this.$api.Device.QrCodeDevice(params).then((res) => {
						if (!res[0] || !res[1]) {
							this.showQrCode = false;
							this.$notify({
								title: "请重试",
								message: "二维码生成失败",
								type: "error",
							});
							this.$refs.xTable.refreshTable();
						} else {
							row.qrcode = res[0];
							row.link_qrcode = res[1];
						}
						that.drawQRCode(row);
					});
				} else {
					this.drawQRCode(row);
				}
			},

			drawQRCode(row) {
				let that = this;
				this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
				// 画图
				this.ctx.fillStyle = "#fff";
				this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
				this.ctx.fillStyle = "#008e4d";
				// this.ctx.textAlign = "left";
				// this.ctx.font = "34px Microsoft YaHei";
				// this.ctx.fillText("设备编号：", 35, 20);
				this.ctx.textAlign = "center";
				let showName = row.device_no + "-" + row.plug_num;
				if (row.plug_name.indexOf('P') != -1) {
					showName = row.plug_name;
				}

				if (showName.length > 20) {
					this.ctx.font = "25px Microsoft YaHei";
				}
				if (showName.length > 28) {
					this.ctx.font = "21px Microsoft YaHei";
				}

				this.ctx.fillText(showName, this.canvas.width / 2, 20);
				const image = new Image();
				image.setAttribute("crossOrigin", "anonymous");
				image.src = row.qrcode;
				image.onload = () => {
					that.ctx.drawImage(image, 35, 75, 430, 430);
					that.qrcode = that.canvas.toDataURL();
				};

				this.ctx2.clearRect(0, 0, this.canvas2.width, this.canvas2.height);
				// 画图
				this.ctx2.fillStyle = "#fff";
				this.ctx2.fillRect(0, 0, this.canvas2.width, this.canvas2.height);
				this.ctx2.fillStyle = "#008e4d";
				// this.ctx2.textAlign = "left";
				// this.ctx2.font = "34px Microsoft YaHei";
				// this.ctx2.fillText("设备编号：", 35, 20);

				this.ctx2.textAlign = "center";
				if (showName.length > 20) {
					this.ctx2.font = "25px Microsoft YaHei";
				}
				if (showName.length > 28) {
					this.ctx2.font = "21px Microsoft YaHei";
				}
				this.ctx2.fillText(showName, this.canvas.width / 2, 20);

				const image2 = new Image();
				image2.setAttribute("crossOrigin", "anonymous");
				image2.src = row.link_qrcode;
				image2.onload = () => {
					that.ctx2.drawImage(image2, 15, 50, 470, 535);
					that.linkQrcode = that.canvas2.toDataURL();
				};
			},

			// 下载二维码
			qrcodeDownload(type) {
				if (type == "wxcode") {
					const aDom = document.createElement("a");
					const event = new MouseEvent("click");
					aDom.download = "插座小程序码_" + this.selPlugDetail.device_no + "_" + this.selPlugDetail.plug_num;
					aDom.href = this.qrcode;
					aDom.target = "_blank";
					aDom.dispatchEvent(event);
				} else if (type == "linkcode") {
					const aDom = document.createElement("a");
					const event = new MouseEvent("click");
					aDom.download = "插座二维码_" + this.selPlugDetail.device_no + "_" + this.selPlugDetail.plug_num;
					aDom.href = this.linkQrcode;
					aDom.target = "_blank";
					aDom.dispatchEvent(event);
				}
			},
			// 关闭二维码
			closedQrcode() {
				this.qrcode = undefined;
				this.linkQrcode = undefined;
			},
			// 列表查询
			async remoteDevice(query) {
				if (query !== "") {
					this.dLoading = true;
					const params = {
						token: this.$store.state.user.token,
						page: 1,
						size: 100,
					};
					const searchData = {
						device_name: query,
					};
					const res = await this.$api.Device.GetDeviceList(params, searchData);
					this.dLoading = false;
					this.deviceList = res.data;
				} else {
					this.deviceList = [];
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.qrcode {
		.device-name {
			text-align: center;
		}

		.qrcode-url {
			width: 250px;
			height: 250px;
			margin: 0 auto;

			img {
				width: 100%;
			}
		}
	}

	.qr-code {
		display: flex;

		.qr-code-box {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 530px;
			height: 300px;

			.qrcode-content {
				height: 300px;
			}
		}
	}

	.add-dialog-radio-group {
		display: flex;
		width: 100%;
		justify-content: center;
		margin-bottom: 20px;
	}
</style>
